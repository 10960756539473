.iq-card {
  .block-images {
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: all 0.6s ease 0s;
    overflow: hidden;

    .img-box {
      border-radius: 0.1875rem;
      overflow: hidden;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(0deg, #0c0b11 8.71%, rgba(12, 11, 17, 0) 72.11%);
        border-radius: 0.1875rem;
        width: 100%;
        height: 100%;
        bottom: 0;
        transition: all 0.5s ease-in-out;
        opacity: 0;
      }

      a {
        z-index: 1;
      }

      img {
        mix-blend-mode: overlay;
        aspect-ratio: 2/3;
      }
    }

    .card-description {
      z-index: 9;
      transition: all 0.4s ease-in-out;
      padding: 1.25rem 1.25rem 0.625rem;
      transform: translate3d(0, 0, 0);
      -webkit-font-smoothing: antialiased;

      .cart-content {
        a {
          font-size: inherit;
          color: inherit;
          text-decoration: none;
        }

        a.watch-list-not {
          font-size: 0.75rem;
          font-weight: $font-weight-medium;
          color: var(--bs-body-color);
        }
      }

      &.with-transition {
        bottom: -4.375rem;
        opacity: 0;
        padding: 1.25rem 0 0;
        visibility: hidden;
        transition: all 0.1s ease;
      }
    }

    &::after {
      position: absolute;
      content: "";
      border: 1.25rem solid var(--bs-gray-900);
      bottom: -5rem;
      left: -1.25rem;
      right: -1.25rem;
      top: -1.25rem;
      opacity: 0;
      transition: all 0.1s ease-in-out;
      visibility: hidden;
      background: transparent;
      border-radius: 0.187rem;
    }
  }

  .movie-time-text {
    color: var(--bs-body-color);
    font-size: 0.75rem;
  }

  .card-description {
    position: absolute;
    left: 0;
    bottom: 0;
    width: auto;
    right: 0;
    transition: all 0.4s ease-in-out;
    padding: 1.25rem 1.25rem 0.625rem;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }

  .block-social-info {
    position: absolute;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    z-index: 999;
    display: flex;
    opacity: 0;
    justify-content: space-between;
    top: inherit;
    transition: opacity 0.2s ease-in-out;

    .share-wrapper {
      position: absolute;
      top: inherit;
      right: 0;
      left: -0.375rem;
      bottom: 80%;
      min-width: inherit;
      height: auto;
      width: 2.375rem;
    }

    .share {
      .share-wrapper {
        display: none;
      }

      &:hover {
        .share-wrapper {
          display: inline-block;
        }
      }
    }

    .music-play-lists {
      li {
        width: 1.625rem;
        height: 1.625rem;
        margin-bottom: 0;
        border-radius: 50%;
        line-height: 2.1875rem;
        margin: 0 auto 0.4375rem;
        background-color: transparent;
        border: 0.0625rem solid var(--bs-white);
        transition: all 0.45s ease 0s;

        &:hover {
          border-color: var(--bs-primary);
          background: var(--bs-primary);
        }
      }

      .share>span {
        width: 100%;
        height: 100%;
        display: inline-block;
        line-height: 0;
        font-size: 1rem;
      }

      span {
        i {
          color: var(--bs-white);
          font-size: 0.625em;
          line-height: 2.5em;
          cursor: pointer;
        }
      }
    }
  }

  .share-boxs {
    .share-shape {
      position: absolute;
      left: 0.75rem;
      top: inherit;
      bottom: -0.875rem;
      transform: rotate(90deg);
      right: 0;
      width: 0.875rem;
      height: 2.5rem;

      path {
        fill: var(--bs-gray-900);
      }
    }

    .overflow-hidden {
      background: var(--bs-gray-900);

      a,
      span {
        display: inline-block;
        width: 2.375rem;
        background: var(--bs-gray-900);
        color: var(--bs-white);
      }
    }

    a {
      &:hover {
        color: var(--bs-primary);
      }
    }
  }

  .iq-button {
    .btn {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0;
      overflow: hidden;
    }
  }

  &:hover {
    .block-images {
      .img-box {
        background: transparent;

        img {
          mix-blend-mode: inherit;
        }

        &::before {
          opacity: 1;
        }
      }

      .card-description {
        background: var(--bs-gray-900);

        &.with-transition {
          opacity: 1;
          visibility: visible;
          transition-delay: 0.2s;

          .cart-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }

          .watchlist {
            transition-delay: 0s;
          }
        }
      }

      &::after {
        opacity: 1;
        transition: all 0.2s ease-in-out;
        visibility: visible;
        transition-delay: 0.2s;
      }
    }

    .block-social-info {
      opacity: 1;
      transition-delay: 0.3s;
    }
  }
}

.card-style-slider {
  li.swiper-slide {
    padding: 0 0.9375rem;
  }
}

// card hover style start

.card-hover {
  &:hover {
    .block-images {
      z-index: 99;
      transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(31.25rem);
      transform-origin: 50% 50%;
      transition: all 0.6s ease 0s;
      box-shadow: none;
      transition-delay: 0.2s;
      overflow: visible;
      margin-top: -1.875rem;
    }
  }
}

.card-style-slider {
  li.swiper-slide {
    &.swiper-slide-active {
      .card-hover {
        &:hover {
          .block-images {
            transform: scale3d(1.1, 1.1, 1) translate3d(12%, 0, 0) perspective(31.25rem);
          }
        }
      }
    }

    &.swiper-active.last {
      .card-hover {
        &:hover {
          .block-images {
            transform: scale3d(1.1, 1.1, 1) translate3d(-12%, 0, 0) perspective(31.25rem);
          }
        }
      }
    }
  }
}

// card hover style start

@media (max-width: 1024px) {
  .iq-card {
    &:hover {
      .block-images {
        margin-top: 0;
      }
    }
  }

  .card-hover:hover .block-images,
  .card-style-slider li.swiper-slide.swiper-slide-active .card-hover:hover .block-images {
    transform: scale3d(1.1, 1.1, 1) translate3d(5%, 0, 0) perspective(31.25em);
  }

  .iq-card:hover .block-social-info {
    opacity: 0;
  }

  .iq-card:hover .block-images::after {
    display: none;
  }

  .iq-card:hover .block-images .card-description.with-transition {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .card-style-slider {
    li.swiper-slide {
      padding: 0 0.4687rem;
      margin-bottom: 0;
    }
  }
}

// card style two
.iq-card-style-2 {
  .block-images {
    .img-box {
      img {
        height: 10.125rem;
        aspect-ratio: 3/2;
      }
    }
  }
}

// card style grid
.card-style-grid {
  .slide-items {
    padding: 0 0.9375em;
    margin-bottom: 1.875em;
  }

  &:hover {
    .block-images {
      margin-top: unset;
    }
  }
}

// card hover style two style start
.card-hover-style-two {
  .block-images {
    .img-box {
      width: 100%;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
      transition: all 0.6s ease 0s;
    }

    &:hover {
      .img-box {
        z-index: 99;
        transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(31.25em);
        transform-origin: 50% 50%;
        transition: all 0.6s ease 0s;
      }
    }
  }
}

li.swiper-slide-active {
  .card-hover-style-two {
    .block-images {
      &:hover {
        .img-box {
          transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(31.25em);
        }
      }
    }
  }
}

li.swiper-active.last {
  .card-hover-style-two {
    .block-images {
      &:hover {
        .img-box {
          transform: scale3d(1.1, 1.1, 1) translate3d(-6%, 0, 0) perspective(31.25em);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .card-hover-style-two {
    .block-images {
      &:hover {
        .img-box {
          transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(31.25em);
        }
      }
    }
  }
}

// card hover style two style end